import { mapGetters } from 'vuex'
// import { APP_ID } from '@/utils/const'
import { isIphoneX } from '@/utils'
import wx from 'weixin-js-sdk'
// import { wxGetskd } from '@/api/wx'  //接口
import router from '@/router'
export default {
  data() {
    return {
      isWx: false,
    }
  },
  computed: {
    ...mapGetters(['platform']),
    isIphoneX() {
      return isIphoneX()
    },
  },
  mounted() { },
  methods: {
    wechatShow() {
      this.isWx = !this.isWx
    },
    download() {
      let iosLinkUrl = 'https://exya1w.xinstall.com.cn/tolink/' // 淘宝 app store 下载地址
      let androidLinkurl =
        'https://a.app.qq.com/o/simple.jsp?pkgname=com.lucky.luckyclient' //(找不到淘宝应用宝地址，这里以lucky coffee为例)
      let platform = this.getServicePlatform()
      console.log('platform', platform)

      switch (platform) {
        case 1: //android
          console.log('android')

          //安卓app的scheme协议
          window.location.href = 'jxjy://ychy/main'

          setTimeout(function () {
            let hidden =
              window.document.hidden ||
              window.document.mozHidden ||
              window.document.msHidden ||
              window.document.webkitHidden
            if (typeof hidden == 'undefined' || hidden == false) {
              //应用宝下载地址 (emmm 找不到淘宝应用宝的地址，这里放的是 lucky coffee 地址)
               window.location.href =
                'https://appgallery.huawei.com/app/C105176027' 
            }
          }, 2000)
          return
        case 2: //ios
          console.log('ios')
          //ios的universalLink协议 
          window.location.href = 'https://exya1w.xinstall.com.cn/tolink/'
          setTimeout(function () {
            let hidden =
              window.document.hidden ||
              window.document.mozHidden ||
              window.document.msHidden ||
              window.document.webkitHidden
            if (typeof hidden == 'undefined' || hidden == false) {
              //App store下载地址
              window.location.href = 'https://apps.apple.com/cn/app/%E6%B4%8B%E8%91%B1%E9%9F%A9%E8%AF%AD/id1596422645'
            }
          }, 2000)
          return
        case 3: //微信
          this.isWx = true
          console.log(this.isWx)
          return
        default:
          return
      }
    },
    getPlatformToSave() {
      // 获取平台，并保存到 vueX
      const platform = this.getServicePlatform()
      this.$store.commit('user/SET_PLATFORM', platform)
    },
    // 判断平台
    getServicePlatform() {
      const ua = navigator.userAgent
      if (ua.match(/MicroMessenger/i) == 'MicroMessenger') {
        // 微信内
        return 3
      }
      if (ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        // ios
        return 2
      }
      if (ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1) {
        // android
        return 1
      }
      return 5 // 浏览器
    },
    // 微信分享
    async getWxShare(item) {
      console.log(location.href.split('#')[0], 'location.href.split')
      console.log(location.href, 'location.href')
      const res = {
        appid: APP_ID,
        url: location.href.split('#')[0],
      }
      const { data, code } = await wxGetskd(res)
      console.log(data, 'getwxshare')
      if (code == 0) {
        wx.config({
          debug: false, // true:是调试模式,调试时候弹窗,会打印出日志
          appId: APP_ID, // 微信appid
          timestamp: data.timestamp, // 时间戳
          nonceStr: data.noncestr, // 随机字符串
          signature: data.sign, // 签名
          jsApiList: [
            // 所有要调用的 API 都要加到这个列表中
            'onMenuShareTimeline', // 分享到朋友圈接口
            'onMenuShareAppMessage', //  分享到朋友接口
            'onMenuShareQQ', // 分享到QQ接口
            'onMenuShareWeibo', // 分享到微博接口
            'updateTimelineShareData',
            'updateAppMessageShareData',
            'showMenuItems',
            'showAllNonBaseMenuItem',
          ],
        })
        wx.checkJsApi({
          jsApiList: [
            // 所有要调用的 API 都要加到这个列表中
            'onMenuShareTimeline', // 分享到朋友圈接口
            'onMenuShareAppMessage', //  分享到朋友接口
            'onMenuShareQQ', // 分享到QQ接口
            'onMenuShareWeibo', // 分享到微博接口
            'updateTimelineShareData',
            'updateAppMessageShareData',
            'showMenuItems',
            'showAllNonBaseMenuItem',
          ],
          success: function (res) {
            // console.log('checkJsApi:success')
          },
        })

        wx.ready(function () {
          // 微信分享的数据
          console.log(item, 'item')
          var shareData = {
            imgUrl: item.shareCoverUrl, // 分享显示的缩略图地址
            link: item.shareLinkUrl, // 分享地址
            desc: item.shareContent, // 分享描述
            title: item.shareTitle, // 分享标题
            success: function () {
              console.log(item, 'readeysuccess')
              // 分享成功可以做相应的数据处理
              // alert('分享成功')
              // alert('appId:' + res.appId)
              // alert('timestamp:' + res.timestamp)
              // alert('nonceStr:' + res.nonceStr)
              // alert('signature:' + res.signature)
            },
            fail: function (error) {
              // alert('调用失败')
              console.log(item, 'readeyfail', error)
            },
            complete: function () {
              // alert('调用结束')
            },
          }
          wx.updateTimelineShareData(shareData)
          wx.updateAppMessageShareData(shareData)
          wx.onMenuShareTimeline(shareData)
          wx.onMenuShareAppMessage(shareData)
        })
        // wx.error(function(res) {
        //   // config信息验证失败会执行error函数，如签名过期导致验证失败，
        //   // 具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，
        //   // 对于SPA可以在这里更新签名。
        //   console.log(res)
        //   // alert('分享失败')
        // })
      }
    },
  },
}
