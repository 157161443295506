<template>
  <div class="container">
    <div
      class="top d-flex align-items-center justify-content-space-between"
      :style="{ background: `#${bg}` }"
      v-if="topShow"
    >
      <div class="logo d-flex align-items-center">
        <img src="@/assets/logo.png" alt="" />
      </div>
      <p>更多学习内容 尽在洋葱韩语app</p>
      <div class="btn flex d-flex justify-content-end" @click="down">
        <span>打开app</span>
        <!-- <span>下载</span> -->
        <!-- 下载 -->
      </div>
    </div>
    <div class="bottom text-center" v-if="bottomShow">
      <span @click="down">App 内打开</span>
      <!-- <span>App 下载</span> -->
      <!-- App下载 -->
    </div>
    <div class="wx-mask" v-if="isWx" @click="change">
      <img src="@/assets/shareTips.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    topShow: {
      type: Boolean,
      default: false,
    },
    bottomShow: {
      type: Boolean,
      default: true,
    },
    bg: {
      type: String,
      default: 'e1e6ff',
    },
    isWx: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },

  created() {
    console.log(this.isWx, '组件')
  },

  methods: {
    down() {
      this.$emit('down')
    },
    change() {
      this.$emit('wechatShow')
    },
  },
}
</script>

<style lang="scss" scoped>
.top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  // background: #e1e6ff;
  // height: 36px;
  padding: 5px 16px;
  z-index: 99;
  .logo {
    height: 27px;
    width: 84px;
    img {
      height: 26px;
      // width: 100%;
    }
  }
  p {
    color: #4e6aff;
    font-size: 12px;
    letter-spacing: -0.3px;
    padding-left: 18px;
  }
  .btn {
    span {
      display: block;
      width: 60px;
      // height: 22px;
      box-sizing: border-box;
      text-align: center;
      background: #4e6aff;
      border-radius: 12px;
      // line-height: 22px;
      padding: 6px 0;
      font-size: 12px;
      color: #fff;
    }
  }
}
.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 28px 0;
  z-index: 95;
  span {
    padding: 7px 12px;
    background: #4e6aff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 18px;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
  }
}
.wx-mask {
  position: fixed;
  z-index: 98;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 17px;
  img {
    width: 100%;
    margin-top: 50px;
  }
}
</style>
